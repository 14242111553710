<template>
  <div
    class="data-card"
    :class="{
      card: listType == 'card',
      list: listType == 'list',
      nojump: (currentTime < dataSource.startTime) ,
    }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <span class="green" v-if="dataSource.complete == 1">
        {{ $t("CM_Completed")
        }}<!-- 已完成 -->
      </span>
      <span class="gray" v-else-if="dataSource.complete == 3">
        {{ $t("CM_Expired")
        }}<!-- 已过期 -->
      </span>
      <span class="red" v-else>
        {{ $t("to_be_done")
        }}<!-- 待完成 -->
      </span>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
      <div class="mask" v-if="currentTime < dataSource.startTime">
        {{ $t("home.not_yet_started")
        }}<!-- 活动暂未开始 -->
      </div>
      <div class="mask" v-if="(currentTime > dataSource.endTime) && (dataSource.complete != 1)">
        {{ $t("home.not_yet_expired")
        }}<!-- 活动已过期 -->
      </div>
    </div>
    <div class="detail">
      <h5>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.taskName }}
            </div>
          </template>
          {{ dataSource.taskName }}
        </a-popover>
      </h5>
      <div class="row mt12">
        {{ $t("CM_StartTime") }}：{{ dateFormat(dataSource.startTime) }}
        <!-- 开始时间 -->
      </div>
      <div class="row mt12">
        {{ $t("CM_EndTime") }}：{{ dateFormat(dataSource.endTime) }}
        <!-- 结束时间 -->
      </div>
      <div class="row mt12 teacher" v-if="dataSource.createUser">
        <div class="portrait">
          <img :src="dataSource.portrait" alt="cover" />
        </div>
        <div class="name">
          <OpenData type="userName" :openid="dataSource.createUser" />
        </div>
        <span>
          {{ $t("activity.assign_me_one") }}
          <!-- 给我指派了一个 -->
          <template v-if="dataSource.dateType == 4">{{
            $t("activity.investigation")
          }}</template>
          <!-- 调查问卷 -->
          <template v-else-if="dataSource.dateType == 7">{{
            $t("activity.evaluate")
          }}</template>
          <!-- 评估表 -->
          <template v-else-if="dataSource.dateType == 11">{{
            $t("activity.vote")
          }}</template>
          <!-- 投票 -->
        </span>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <template v-if="currentTime > dataSource.startTime">
        <div
          class="btn"
          v-if="dataSource.complete == 2 || dataSource.complete == 0"
        >
          {{ $t("activity.participate")
          }}<!-- 参与活动 -->
        </div>
        <div
            class="btn"
            v-else-if="dataSource.complete == 3"
        >
          {{ $t("CM_Expired")
          }}<!-- 已过期 -->
        </div>
        <div class="btn" v-else>
          {{ $t("CM_ViewResult")
          }}<!-- 查看结果 -->
        </div>
      </template>
      <div class="btn" v-else>
        {{ $t("Pub_Tab_NotStart")
        }}<!-- 未开始 -->
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "ActivityCard",
  components: {
    OpenData,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();
    const currentTime = Date.parse(new Date()) / 1000;
    const jump = () => {
      if ((currentTime < props.dataSource.startTime)) return;
      if (props.newWindow) {
        window.open(
          `/questionnaire/detail?ddtab=true&id=${props.dataSource.taskId}&did=${props.dataSource.detailId}&resourceId=${props.dataSource.resourceId}&progress=${props.dataSource.progress}&name=${props.dataSource.taskName}&complete=${props.dataSource.complete}&source=activity`
        );
      } else {
        router.push({
          path: "/questionnaire/detail",
          query: {
            source: "activity",
            id: props.dataSource.taskId,
            did: props.dataSource.detailId,
            resourceId: props.dataSource.resourceId,
            progress: props.dataSource.progress,
            name: props.dataSource.taskName,
            complete: props.dataSource.complete,
          },
        });
      }
    };

    return {
      dateFormat,
      currentTime,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  .cover {
    position: relative;
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .mixinFlex(center; center);
      color: transparent;
      font-size: 14px;
      font-weight: 600;
      background-color: rgba(0, 0, 0, 0);
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
  &:hover {
    .cover {
      .mask {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
        color: #fff;
      }
    }
  }
  .row.teacher {
    .mixinFlex(flex-start; center);
    .portrait {
      .mixinImgWrap(24px; 24px);
      border-radius: 50%;
      margin-right: 6px;
    }
    .name {
      margin-right: 16px;
    }
  }
}
</style>
