<template>
  <div class="list-type">
    <div
      class="item"
      :class="{ active: checked == 'list' }"
      @click="change('list')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3.49219 3.46875V9.65625H9.5625V3.46875H3.49219ZM2.64844 1.78125H10.4062C10.8722 1.78125 11.25 2.15902 11.25 2.625V10.5C11.25 10.966 10.8722 11.3438 10.4062 11.3438H2.64844C2.18245 11.3438 1.80469 10.966 1.80469 10.5V2.625C1.80469 2.15902 2.18245 1.78125 2.64844 1.78125ZM3.49219 14.3438V20.5312H9.5625V14.3438H3.49219ZM2.64844 12.6562H10.4062C10.8722 12.6562 11.25 13.034 11.25 13.5V21.375C11.25 21.841 10.8722 22.2188 10.4062 22.2188H2.64844C2.18245 22.2188 1.80469 21.841 1.80469 21.375V13.5C1.80469 13.034 2.18245 12.6562 2.64844 12.6562ZM14.4489 4.82812C13.9829 4.82812 13.6051 4.45036 13.6051 3.98438C13.6051 3.51839 13.9829 3.14062 14.4489 3.14062H21.1875C21.6535 3.14062 22.0312 3.51839 22.0312 3.98438C22.0312 4.45036 21.6535 4.82812 21.1875 4.82812H14.4489ZM14.4489 9.98438C13.9829 9.98438 13.6051 9.60661 13.6051 9.14062C13.6051 8.67464 13.9829 8.29688 14.4489 8.29688H21.1875C21.6535 8.29688 22.0312 8.67464 22.0312 9.14062C22.0312 9.60661 21.6535 9.98438 21.1875 9.98438H14.4489ZM14.4489 15.7031C13.9829 15.7031 13.6051 15.3254 13.6051 14.8594C13.6051 14.3934 13.9829 14.0156 14.4489 14.0156H21.1875C21.6535 14.0156 22.0312 14.3934 22.0312 14.8594C22.0312 15.3254 21.6535 15.7031 21.1875 15.7031H14.4489ZM14.4489 20.8594C13.9829 20.8594 13.6051 20.4816 13.6051 20.0156C13.6051 19.5496 13.9829 19.1719 14.4489 19.1719H21.1875C21.6535 19.1719 22.0312 19.5496 22.0312 20.0156C22.0312 20.4816 21.6535 20.8594 21.1875 20.8594H14.4489Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
    <div
      class="item"
      :class="{ active: checked == 'card' }"
      @click="change('card')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3.49219 3.46875V9.65625H9.5625V3.46875H3.49219ZM2.64844 1.78125H10.4062C10.8722 1.78125 11.25 2.15902 11.25 2.625V10.5C11.25 10.966 10.8722 11.3438 10.4062 11.3438H2.64844C2.18245 11.3438 1.80469 10.966 1.80469 10.5V2.625C1.80469 2.15902 2.18245 1.78125 2.64844 1.78125ZM3.49219 14.3438V20.5312H9.5625V14.3438H3.49219ZM2.64844 12.6562H10.4062C10.8722 12.6562 11.25 13.034 11.25 13.5V21.375C11.25 21.841 10.8722 22.2188 10.4062 22.2188H2.64844C2.18245 22.2188 1.80469 21.841 1.80469 21.375V13.5C1.80469 13.034 2.18245 12.6562 2.64844 12.6562Z"
          fill="#CCCCCC"
        />
        <path
          d="M14.6875 3.46875V9.65625H20.7578V3.46875H14.6875ZM13.8438 1.78125H21.6016C22.0675 1.78125 22.4453 2.15902 22.4453 2.625V10.5C22.4453 10.966 22.0675 11.3438 21.6016 11.3438H13.8438C13.3778 11.3438 13 10.966 13 10.5V2.625C13 2.15902 13.3778 1.78125 13.8438 1.78125ZM14.6875 14.3438V20.5312H20.7578V14.3438H14.6875ZM13.8438 12.6562H21.6016C22.0675 12.6562 22.4453 13.034 22.4453 13.5V21.375C22.4453 21.841 22.0675 22.2188 21.6016 22.2188H13.8438C13.3778 22.2188 13 21.841 13 21.375V13.5C13 13.034 13.3778 12.6562 13.8438 12.6562Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "listType",
  props: {
    checked: {
      type: String,
      default: "card",
    },
  },
  emits: ["change"],
  setup(_, { emit }) {
    const change = (type) => {
      emit("change", type);
    };

    return {
      change,
    };
  },
};
</script>

<style lang="less" scoped>
.list-type {
  padding: 12px 0;
  .mixinFlex(flex-end);
  .item {
    margin-left: 20px;
    height: 24px;
    cursor: pointer;
    svg {
      path {
        transition: fill 0.2s;
      }
    }
    &:hover {
      svg {
        path {
          fill: #aaa;
        }
      }
    }
    &.active {
      svg {
        path {
          fill: @color-theme;
        }
      }
    }
  }
}
</style>
